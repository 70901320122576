import { IOption } from "../../../types_new/IOption";
// import { IOption, IActionOption } from "../types_new/IOption";
// import { IEventResponse } from "../types_new/IEvent";
// import EventService from "../services_new/eventService";

export const sortOptions: IOption[] = [
  {
    value: "createdAt",
    text: "Fecha",
  },
  {
    value: "name",
    text: "Nombre",
  },
];

export const statusOptions: IOption[] = [
  {
    value: "all",
    text: "Todos los eventos",
  },
  {
    value: "active",
    text: "Activos",
  },
  {
    value: "paused",
    text: "Pausados",
  },
  {
    value: "pending_approval",
    text: "Esperando aprobación",
  },
  {
    value: "finished",
    text: "Finalizados",
  },
];

// export const actionOptions: IActionOption<IEventResponse>[] = [
//   {
//     value: "active",
//     text: "Cambiar a Activo",
//     callback: EventService.updateStatus,
//     payload: "active",
//   },
//   {
//     value: "finished",
//     text: "Cambiar a Finalizado",
//     callback: EventService.updateStatus,
//     payload: "finished",
//   },
//   {
//     value: "pending_approval",
//     text: "Cambiar a Pendiente de Aprobación",
//     callback: EventService.updateStatus,
//     payload: "pending_approval",
//   },
//   {
//     value: "paused",
//     text: "Cambiar a Pausado",
//     callback: EventService.updateStatus,
//     payload: "paused",
//   },
// ];
