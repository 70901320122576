import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { StateContext } from "../../../context/StateProvider";
import { Back } from "../../Back";
import { MemberTeam } from "./team/MemberTeam";
import { useNavigate } from "react-router";
import FormInput from "../../FormElement/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { IEventRequest, IEventResponse } from "../../../types/IEvent";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { useMutation } from "@tanstack/react-query";
import EventService from "../../../services/eventService";
import eventService from "../../../services/eventService";
import { useParams } from "react-router-dom";
import FormDateTimeField from "../../FormElement/FormDateTimeField";
import FormSelect from "../../FormElement/FormSelect";
import { setFormErrorsFromServer } from "../../../utils/form";
import { AuthContext } from "../../../context/AuthContext";
import teamService from "../../../services/teamService";
import ArtistService from "../../../services/artistService";
import { ArtistEvent } from "./artist/artistEvent";
import FormGooglePlacesAutocomplete from "../../FormElement/FormGooglePlacesAutocomplete";
import moment from "moment/moment";
import FormCropperImage from "../../FormElement/FormCropperImage";
import { toast } from "react-toastify";
import FormCheck from "../../FormElement/FormCheck";
import { buildISODateTimeString } from "../../../utils/format-date";
import config from "../../../config/variables";
import FormSwitch from "../../FormElement/FormSwitch";
import FormTextArea from "../../FormElement/FormTextArea";
import { Checkbox } from "@/components/ui/checkbox";

type NewEventProps = {
  edit?: boolean;
  data?: IEventResponse;
  setEditEvent: Dispatch<SetStateAction<boolean>>;
  handleCreateEvent?: (data?: any) => void;
};

const defaultValues = {
  name_first_row: "",
  name_second_row: "",
  about: "",
  date_start: "",
  time_start: "",
  date_end: "",
  soldout: "",
  available_for_resell: true,
  time_end: "",
  publish_date_start: "",
  publish_time_start: "",
  publish_date_end: "",
  publish_time_end: "",
  status: "pending_approval",
  // home_banner: "",
  banner: "",
  promo_image: "",
  flyer_image: "",
  team_ids: [],
  place: {},
  link: config.store_url,
  id: "",
  limit_date: "",
  limit_time: "",
  event_alias: "",
  installments: 1,
  customer_id: "",
  place_name: "",
  producer_name: "",
  payment_methods: ["mercadopago", "binance", "modo"],
};

const statusOptions: Array<object> = [
  { value: "pending", text: "Pendiente" },
  { value: "active", text: "Activo" },
  { value: "paused", text: "Pausado" },
];

const statusOptionsProducer: Array<object> = [
  { value: "active", text: "Activo" },
  { value: "paused", text: "Pausado" },
  { value: "finished", text: "Finalizado" },
];

const imageValidation = yup
  .mixed()
  .test("size", "Tamaño permitido menor a 20MB", (value) => {
    if (value instanceof File) {
      return value.size <= 20000000;
    }
    return true;
  });

export const NewEvent = ({
  edit,
  data,
  setEditEvent,
  handleCreateEvent = () => {},
}: NewEventProps) => {
  const [dateTimeStart, setDateTimeStart] = useState<any>();
  const schema = yup.object().shape({
    name_first_row: yup.string().required("Campo requerido"),
    name_second_row: yup.string().required("Campo requerido"),
    about: yup.string().required("Campo requerido"),
    date_start: yup.string().required("Campo requerido"),
    time_start: yup.string().required("Campo requerido"),
    date_end: yup.string().required("Campo requerido"),
    time_end: yup.string().required("Campo requerido"),
    publish_time_start: yup.string().required("Campo requerido"),
    publish_time_end: yup.string().required("Campo requerido"),
    status: yup.string(),
    promo_image: imageValidation.concat(
      yup.mixed().test("required", "Campo requerido", (value) => {
        return value ? true : false;
      })
    ),
    banner: imageValidation,
    installments: yup
      .number()
      .integer("Debe ser un número entero")
      .min(1, "El número mínimo de cuotas es 1")
      .max(18, "El número máximo de cuotas es 18"),
    limit_time: yup
      .string()
      .required("Campo requerido")
      .test(
        "max-limit-date",
        "La hora límite debe ser menor a la hora de inicio de evento",
        (hour) => {
          if (hour && dateTimeStart) {
            const value = moment(
              dateTimeStart.format("YYYY-MM-DD") + " " + hour
            );
            console.info("value", value);
            console.info("dateTimeStart", dateTimeStart);
            return value < dateTimeStart;
          }
          return true;
        }
      ),
    place_name: yup.string().required("Campo requerido"),
    producer_name: yup.string().required("Campo requerido"),
    payment_methods: yup.array().of(yup.string()),
  });

  const today = new Date();
  const dateStartMin =
    data && data.id ? "" : moment(today).utcOffset(0).format("YYYY-MM-DD");

  const dateDataToday = moment(today).utcOffset(0);

  const navigate = useNavigate();
  const { producer_id } = useParams();
  const auth = useContext(AuthContext);
  // const authClientProducerID = auth.role == 'client' && auth.producer.id && auth.producer.id.length ? auth.producer.id : undefined;
  const authClientProducerID =
    auth.role == "producer" && auth.user.id && auth.user.id.length
      ? auth.user.id
      : undefined;
  const producerID = producer_id || authClientProducerID || undefined;
  const form = useForm<IEventRequest>({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, producer_id: producerID },
    mode: "all",
  });
  const createItems = useMutation((item: FormData) =>
    EventService.create(item)
  );
  const updateItems = useMutation((item: FormData) =>
    EventService.update(data && data.id ? data.id : undefined, item)
  );
  const getMembers = useMutation(
    () =>
      teamService.getAll({
        producer_producer_id: producer_id || auth.producer?.id,
        producer_event_id: data?.id,
        team_role: "leader",
      })
    // RrppService.getAll({ sortBy: 'first_name', sortOrder: 'asc', team_role: 'leader', producer_producer_id: auth.producer?.id })
  );

  const getValidators = useMutation(() =>
    teamService.getAll({
      producer_producer_id: producer_id || auth.producer?.id,
      producer_event_id: data?.id,
      team_role: "validator",
      status: "active",
    })
  );

  const getArtists = useMutation(() => ArtistService.getAll({}));

  const { state, dispatch } = useContext(StateContext);

  const [initDataStart, setInitDataStart] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [limitTimeStart, setLimitTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [iniDatePublishStart, setIniDatePublishStart] = useState("");
  const [limitDatePublishEnd, setLimitDatePublishEnd] = useState("");
  const statusEvent = useRef("");
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    string[]
  >(defaultValues.payment_methods);

  const handlePaymentMethodChange = (method: string) => {
    setSelectedPaymentMethods((prev) => {
      if (prev.includes(method)) {
        return prev.filter((m) => m !== method);
      } else {
        return [...prev, method];
      }
    });
  };

  const onSubmit = async (dataForm: IEventRequest) => {
    // checks if place input is empty, in such case overwrites previous place for an empty object
    const placeInputDiv = document.getElementById("place-input-div")!;
    const autocompleteInput = placeInputDiv.querySelector(
      "input:last-of-type"
    ) as HTMLInputElement;
    const inputValue = autocompleteInput.value;
    if (inputValue === "") {
      dataForm.place = {};
    }

    if (statusEvent.current && statusEvent.current.length > 0) {
      dataForm.admin_action = statusEvent.current;
    }

    let flag = true;
    if (dataForm.date_start) {
      const dataChange = moment(dataForm.date_start)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      const dateSetLimit = moment(dataForm.date_end)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      if (dataChange <= dateSetLimit) {
        setInitDataStart(dataChange);
      } else {
        flag = false;
        setDataEnd("");
        setInitDataStart(dataChange);
        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la fecha de inicio del evento no debe ser mayor a la fecha de finalizacion"
        );
      }
      setLimitDatePublishEnd(dataChange);
    }

    if (dataForm.date_end) {
      const dataChange = moment(dataForm.date_end)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      setDataEnd(dataChange);
      const dateSetLimit = moment(dataForm.date_start)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      if (dataChange >= dateSetLimit) {
        setDataEnd(dataChange);
      } else {
        flag = false;
        setInitDataStart("");
        setDataEnd(dataChange);

        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la fecha de finalizacion del evento no debe ser menor a la fecha de inicio"
        );
      }
    }

    if (dataForm.time_start) {
      const dataChange = moment(
        dataForm.date_start.substring(0, 10) + " " + dataForm.time_start
      );
      const dataFinOld = moment(dataEnd + " " + dataForm.time_end);
      const dataFin = moment(
        dataForm.date_end.substring(0, 10) + " " + dataForm.time_end
      );
      console.info("FechaInicio-->", dataChange);
      console.info("FechaFin-->", dataFin);
      console.info("FechaFinOld-->", dataFinOld);
      if (dataChange > dataFin) {
        flag = false;
        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la hora de inicio de evento no debe de ser mayor a la hora de finalizacion"
        );
      }
    }

    if (dataForm.time_end) {
      const dataChange = moment(
        dataForm.date_end.substring(0, 10) + " " + dataForm.time_end
      );

      const dataFin = moment(
        dataForm.date_start.substring(0, 10) + " " + dataForm.time_start
      );
      if (dataChange < dataFin) {
        flag = false;
        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la hora de fin de evento no debe de ser menor a la hora de inicio"
        );
      }
    }

    if (edit == false) {
      if (dataForm.publish_time_start) {
        const dataChange = moment(iniDatePublishStart).utcOffset(0);
        const dateAct = dataChange.add(dataForm.publish_time_start);

        if (dataChange >= dateDataToday) {
          const dataFin = moment(dataEnd).utcOffset(0);
          const dateFinAdd = dataFin.add(dataForm.publish_time_end);
          if (dateAct > dateFinAdd) {
            flag = false;
            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error(
              "la hora de publicacion del evento no debe de ser mayor a la hora de fin de publicacion"
            );
          }
        } else {
          flag = false;
          dispatch({ type: "showLoaderScreen", payload: false });
          toast.error(
            "la hora de publicacion de evento no debe de ser menor a la hora de actual"
          );
        }
      }

      if (dataForm.publish_time_end) {
        const dataChange = moment(iniDatePublishStart).utcOffset(0);
        const dateAct = dataChange.add(dataForm.publish_time_start);

        const dataFin = moment(dataEnd).utcOffset(0);
        const dateFinAdd = dataFin.add(dataForm.publish_time_end);
        if (dataFin >= dateDataToday) {
          if (dateFinAdd < dateAct) {
            flag = false;
            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error(
              "la hora de fin de publicacion de evento no debe de ser mayor a la hora de publicacion"
            );
          }
        } else {
          flag = false;
          dispatch({ type: "showLoaderScreen", payload: false });
          toast.error(
            "la hora de expiracion de evento no debe de ser menor a la hora de actual"
          );
        }
      }
    }

    if (dataForm.publish_date_start) {
      const dataChange = moment(dataForm.publish_date_start)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      const dateSetLimit = moment(limitDatePublishEnd)
        .utcOffset(0)
        .format("YYYY-MM-DD");

      if (dataChange <= dateSetLimit) {
        setIniDatePublishStart(dataChange);
      } else {
        flag = false;
        setLimitDatePublishEnd("");
        setIniDatePublishStart(dataChange);
        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la fecha de publicacion no debe ser mayor a la fecha de finalizacion de publicacion"
        );
      }
    }

    if (dataForm.publish_date_end) {
      const dataChange = moment(dataForm.publish_date_end)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      const dateSetIni = moment(iniDatePublishStart)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      if (dataChange >= dateSetIni) {
        setLimitDatePublishEnd(dataChange);
      } else {
        flag = false;
        setIniDatePublishStart("");
        setLimitDatePublishEnd(dataChange);

        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la fecha final de publicacion no debe de ser menor a la fecha de publicacion"
        );
      }
    }
    // }

    if (flag == true) {
      console.log(state.members);

      if (auth.producer?.id || producer_id) {
        dataForm.producer_id = producer_id || auth.producer?.id || "";
      }
      dataForm.team_ids = state.members?.map((item) => {
        return item.id;
      });
      dataForm.artist_ids = state.selectedArtists?.map((item) => {
        return item.id;
      });
      dataForm.artists = state.selectedArtists?.map((artist) => {
        return { id: artist.id, is_main_artist: artist.is_main_artist };
      });
      dataForm.validator_ids = state.selectedValidators?.map((item) => {
        return item.id;
      });
      dataForm.validator_ids.push("");
      dataForm.commissions = state.membersAssign;

      if (dataForm.limit_time)
        dataForm.limit_date = moment(
          dateTimeStart.format("YYYY-MM-DD") + " " + dataForm.limit_time
        ).toISOString();

      dataForm.date_start = buildISODateTimeString(
        dataForm.date_start,
        dataForm.time_start
      );
      dataForm.date_end = buildISODateTimeString(
        dataForm.date_end,
        dataForm.time_end
      );
      dataForm.publish_date_start = buildISODateTimeString(
        dataForm.publish_date_start,
        dataForm.publish_time_start
      );
      dataForm.publish_date_end = buildISODateTimeString(
        dataForm.publish_date_end,
        dataForm.publish_time_end
      );

      if (auth.role == "admin" && !edit) {
        dataForm.status = "active";
      }

      dataForm.payment_methods = selectedPaymentMethods;

      let formData = jsonToFormData(dataForm, {
        arrayIndexes: true,
        excludeNull: true,
      });

      dispatch({ type: "showLoaderScreen", payload: true });
      if (edit && edit == true) {
        if (dataForm.soldout == null) {
          dataForm.soldout = "false";
          formData = jsonToFormData(dataForm, {
            arrayIndexes: true,
            excludeNull: true,
          });
        }

        updateItems
          .mutateAsync(formData)
          .then((res) => {
            dispatch({ type: "showLoaderScreen", payload: false });
            if (auth.role == "admin") {
              dispatch({
                type: "toastSuccess",
                payload: "Registro actualizado",
              });
            } else {
              if (res.temp) {
                dispatch({
                  type: "toastSuccess",
                  payload:
                    "Los cambios serán revisados por un administrador antes de publicarse",
                });
              } else {
                dispatch({
                  type: "toastSuccess",
                  payload: "Registro actualizado",
                });
              }
            }
          })
          .catch((reason) => {
            setFormErrorsFromServer(reason, form.setError, form.setFocus);
            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error("Ingrese los datos correctamente");
          });
      } else {
        createItems
          .mutateAsync(formData)

          .then((res) => {
            dispatch({ type: "showLoaderScreen", payload: false });
            if (auth.role != "admin") {
              dispatch({ type: "message", payload: null });
            }
          })
          .catch((reason) => {
            setFormErrorsFromServer(reason, form.setError, form.setFocus);
            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error("Ingrese los datos correctamente");
          });
      }
      navigate(getUrlToNavigate());
      setEditEvent(false);
      handleCreateEvent();
    } else {
      dispatch({ type: "showLoaderScreen", payload: false });
      toast.error("Ingrese los datos correctamente");
    }
  };

  const onError = (errors: any) => {
    if (Object.keys(errors).length > 0) {
      const firstError: any = Object.keys(errors)[0] as keyof typeof errors;
      form.setFocus(firstError);
    }
  };

  const getItem = useMutation(() =>
    eventService.get(data && data.id ? data.id : "")
  );

  useEffect(() => {
    getMembers.mutateAsync().then((res2) => {
      dispatch({ type: "setAvailableMembers", payload: res2.rows });
    });
    getValidators.mutateAsync().then((res) => {
      const repeatValIds: Array<string> = [];
      const resValidators = res.rows.filter((r) => {
        if (repeatValIds.indexOf(r.user_id) > -1) return false;
        else {
          repeatValIds.push(r.user_id);
          return true;
        }
      });
      dispatch({ type: "setValidators", payload: resValidators });
    });
    if (data && data.id) {
      getItem.mutateAsync().then((res) => {
        if (res.date_start) {
          const mds = moment(res.date_start);
          setDateTimeStart(mds);
        }

        const dateStart = moment(res.date_start);
        res.date_start = dateStart.format();
        setInitDataStart(dateStart.format("YYYY-MM-DD"));

        const dateEnd = moment(res.date_end);
        res.date_end = dateEnd.format();
        setDataEnd(dateEnd.format("YYYY-MM-DD"));
        setLimitTimeStart(res.date_start);

        const dateStartPublishIni = moment(res.publish_date_start);
        res.publish_date_start = dateStartPublishIni.format();
        setIniDatePublishStart(dateStartPublishIni.format("YYYY-MM-DD"));

        const dateStartPublish = moment(res.publish_date_end);
        res.publish_date_end = dateStartPublish.format();
        setLimitDatePublishEnd(dateStartPublish.format("YYYY-MM-DD"));

        // Map payment_methods from the fetched event data
        if (res.payment_methods) {
          setSelectedPaymentMethods(res.payment_methods);
        }

        form.reset(res);

        // reload Artist
        dispatch({ type: "selectedArtists", payload: res.artist_ids });

        // reload members
        const r = res.team_ids
          ? res.team_ids.map(({ team_id }) => team_id)
          : [];

        // reload Validators
        let validatorsIds = res.members
          ? res.members.filter(
              (m) =>
                m.team_role == "validator" && m.user[0] && m.status == "active"
            )
          : [];
        dispatch({ type: "selectedValidators", payload: validatorsIds });

        const rex: string[] = [];
        r.map((value) => {
          const found = state.availableMembers.some((el) => el.id == value);
          if (found) {
            rex.push(value);
          }
        });
        dispatch({ type: "setMembers", payload: rex });
      });
    } else {
      dispatch({ type: "setMembers", payload: [] });
      dispatch({ type: "selectedArtists", payload: [] });
      dispatch({ type: "selectedValidators", payload: [] });
      const dateStart = moment().utcOffset(0).format("YYYY-MM-DD");
      setInitDataStart(dateStart);
      form.setValue("date_start", dateStart);

      const dateFin = moment().utcOffset(0).format("YYYY-MM-DD");
      setDataEnd(dateFin);
      form.setValue("date_end", dateFin);

      const publish_date_start = moment().utcOffset(0).format("YYYY-MM-DD");
      setIniDatePublishStart(publish_date_start);
      form.setValue("publish_date_start", publish_date_start);

      const publish_date_end = moment().utcOffset(0).format("YYYY-MM-DD");
      setLimitDatePublishEnd(publish_date_end);
      form.setValue("publish_date_end", publish_date_end);
    }
  }, [form.reset, data]);

  useEffect(() => {
    let formDateStart = form.getValues("date_start");
    let formTimeStart = form.getValues("time_start");
    if (formDateStart && formTimeStart) {
      const mds = formDateStart.substring(0, 10) + " " + formTimeStart;
      setDateTimeStart(moment(mds));
    } else {
      setDateTimeStart("");
    }
  }, [form.watch("time_start"), form.watch("date_start")]);

  // Inputs dates
  const handleOnChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const dataChange = moment(e.target.value)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      setInitDataStart(dataChange);
    }
  };

  const handleOnChangeDateEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const dataChange = moment(e.target.value)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      setDataEnd(dataChange);
    }
  };

  const handleOnChangePublishDateIni = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value) {
      const dataChange = moment(e.target.value)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      setIniDatePublishStart(dataChange);
    }
  };

  const handleOnChangePublishDate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("change date 4", e);
    if (e.target.value) {
      const dataChange = moment(e.target.value)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      setLimitDatePublishEnd(dataChange);
    }
  };

  const handleOnChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setLimitTimeStart(e.target.value);
    }
  };

  const handleOnChangeEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setTimeEnd(e.target.value);
    }
  };

  // Members
  const addTeamMember = () => {
    dispatch({ type: "addTeamMember", payload: null });
  };

  const showTeam = () => {
    dispatch({ type: "showTeam", payload: null });
  };

  const handleRemoveId = (id: string) => {
    dispatch({ type: "removeMember", payload: id });
  };

  // Artists
  const addArtistEvent = async () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    getArtists
      .mutateAsync()
      .then((result) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "setArtists", payload: result.rows });
        dispatch({ type: "addArtistEvent", payload: null });
      })
      .catch((reason) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastError",
          payload: "No pudimos cargar los artistas",
        });
      });
  };

  const addValidatorEvent = () => {
    dispatch({ type: "addValidatorEvent", payload: null });
  };

  const handleRemoveArtist = (id: string) => {
    dispatch({ type: "removeSelectedArtist", payload: id });
  };

  const handleRemoveValidator = (id: string) => {
    dispatch({ type: "removeSelectedValidator", payload: id });
  };

  const handleSetMainArtist = (id: string) => {
    const newArtists = state.selectedArtists.map((artist) => {
      if (artist.id === id) {
        return {
          ...artist,
          is_main_artist: !artist.is_main_artist,
        };
      }
      return artist;
    });

    dispatch({ type: "selectedArtists", payload: newArtists });
  };

  const getUrlToNavigate = () => {
    const url = window.location.pathname;

    // Remove possible trailing slashes
    const trimmedUrl = url.replace(/\/+$/, "");
    if (trimmedUrl.endsWith("/edit")) {
      // Remove '/edit' from the end
      return trimmedUrl.slice(0, -5);
    }

    return trimmedUrl;
  };

  const handleBack = () => {
    if (edit) {
      navigate(getUrlToNavigate());
      setEditEvent(false);
    } else {
      handleCreateEvent();
    }
  };

  return (
    <div className="">
      <Back onClick={handleBack} />
      <div className="flex flex-col gap-10 px-[15%] xl:px-[224px]">
        <h1 className="text-center text-3xl font-bold">
          {edit && edit == true ? "Editar" : "Crear nuevo"} evento
        </h1>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, onError)}
            className="flex flex-col gap-x-10"
          >
            <FormInput
              name="name_first_row"
              type="text"
              label="Titulo del evento - Primera fila"
            />
            <FormInput
              name="name_second_row"
              type="text"
              label="Titulo del evento - Segunda fila"
            />
            <div className="flex justify-center gap-10 pb-5">
              {/* <FormCropperImage
                name="home_banner"
                label="Banner home (Tamaño: 920 x 480)"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={920 / 480}
                cropBoxResizable={false}
                dragMode="move"
                maxWidth={920}
                maxHeight={480}
              /> */}
              <FormCropperImage
                name="banner"
                label="Banner Evento destacado Web (Tamaño: 1360 x 500)"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={2.7}
                cropBoxResizable={false}
                dragMode="move"
                maxWidth={1350}
                maxHeight={500}
              />
              <FormCropperImage
                name="promo_image"
                label="Flyer del evento  (Tamaño feed instagram 1000x1000)"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={1}
                cropBoxResizable={false}
                dragMode="move"
                maxWidth={1000}
                maxHeight={1000}
              />
            </div>
            <div className="flex gap-10">
              <FormDateTimeField
                name="date_start"
                type="date"
                label="El evento comienza"
                min={dateStartMin}
                onChangeCustom={handleOnChangeStartDate}
              />
              <FormDateTimeField
                name="time_start"
                type="time"
                label="Hora que comienza el evento"
                onChangeCustom={handleOnChangeStartTime}
              />
            </div>
            <div className="flex gap-10">
              <FormDateTimeField
                name="date_end"
                type="date"
                label="El evento termina"
                // min={initDataStart}
                onChangeCustom={handleOnChangeDateEnd}
              />
              <FormDateTimeField
                name="time_end"
                type="time"
                label="Hora que termina el evento"
                onChangeCustom={handleOnChangeEndTime}
                // min={limitTimeStart}
              />
            </div>
            <div className="flex gap-10">
              <FormDateTimeField
                name="publish_date_start"
                type="date"
                label="Fecha de publicación"
                onChangeCustom={handleOnChangePublishDateIni}
                min={dateStartMin}
                // max={initDataStart}
              />
              <FormDateTimeField
                name="publish_time_start"
                type="time"
                label="Hora de publicación"
              />
            </div>
            <div className="flex gap-10">
              <FormDateTimeField
                name="publish_date_end"
                type="date"
                label="Fecha fin de publicación"
                onChangeCustom={handleOnChangePublishDate}
                // min={initDataStart}
                // max={initDataStart}
              />
              <FormDateTimeField
                name="publish_time_end"
                type="time"
                label="Hora fin de publicación"
              />
            </div>
            <div className="flex gap-10">
              <FormDateTimeField
                name="limit_time"
                type="time"
                label={`Venta de tickets hasta`}
              />
            </div>
            <div className="flex gap-10 pb-5">
              <div className="w-full ">
                <FormCheck
                  name="soldout"
                  label="Sold-out"
                  options={[{ value: "true", label: "Sold-out" }]}
                />
              </div>
            </div>
            <FormInput
              name="order"
              type="number"
              label="Número de orden"
              min="0"
            />

            {auth.role === "admin" &&
              auth.permissions.admin_access === "super_admin" && (
                <FormInput
                  name="installments"
                  type="number"
                  label="Número de cuotas"
                  min="1"
                  max="18"
                />
              )}

            <div className="flex gap-10">
              <div className="w-1/2">
                <FormSwitch name="hidden" type="text" label="Oculto" />
                <FormSwitch
                  name="listed_for_sellers"
                  type="text"
                  label="Visible para vendedores (sólo si el evento esta oculto)"
                />
                <FormSwitch
                  name="available_for_resell"
                  type="text"
                  label="Disponible Reventa"
                />
              </div>

              <div className="w-1/2">
                {auth.role === "admin" &&
                  auth.permissions.admin_access === "super_admin" && (
                    <>
                      <h2 className="mb-4 text-xl font-bold">
                        Métodos de Pago
                      </h2>
                      <div className="flex flex-col space-y-2">
                        {["mercadopago", "binance", "modo"].map((method) => (
                          <div
                            key={method}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              id={`checkbox-${method}`}
                              checked={selectedPaymentMethods.includes(method)}
                              onCheckedChange={() =>
                                handlePaymentMethodChange(method)
                              }
                            />
                            <label
                              htmlFor={`checkbox-${method}`}
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {method.charAt(0).toUpperCase() + method.slice(1)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
              </div>
            </div>

            <FormTextArea
              name="about"
              type="text"
              label="Descripción del evento"
            />
            {/* <FormInput name="link" type="text" label="Link" /> */}
            <FormInput name="social_media" type="text" label="Red Social" />
            <FormInput name="customer_id" type="text" label="Customer ID" />
            <FormInput name="event_alias" type="text" label="Event Alias" />
            <FormGooglePlacesAutocomplete
              name="place"
              type="text"
              label="Ubicación"
              placeholder="Elegir lugar..."
            />
            <FormInput name="place_name" type="text" label="Ciudad" />
            <FormInput
              name="producer_name"
              type="text"
              label="Nombre de la fiesta (1 o 2 palabras)"
            />

            {edit &&
            (auth.role == "admin" ||
              (auth.role == "producer" && data?.status != "pending")) ? (
              <FormSelect
                name="status"
                options={
                  auth.role == "admin" ? statusOptions : statusOptionsProducer
                }
                label="Estado del evento..."
              />
            ) : null}

            {state.showTeam || edit === true ? (
              <div className="relative mb-5  min-h-52 items-center justify-center rounded-lg border border-colorBorder p-10">
                <p className="absolute left-8 top-4 font-semibold text-gray-100">
                  Equipo
                </p>
                <button
                  type="button"
                  className="absolute right-8 top-4 font-bold "
                  onClick={addTeamMember}
                >
                  + Asignar equipo
                </button>
                {state.members ? (
                  <div className="flex flex-wrap justify-center gap-4 pt-5">
                    {state.members.map((_, i) => (
                      <div key={i}>
                        <MemberTeam data={_} handleRemoveId={handleRemoveId} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              <div className="relative mb-5 flex  min-h-52 items-center justify-center rounded-lg border border-colorBorder p-10">
                <p className="absolute left-8 top-4 font-semibold text-gray-100">
                  Equipo
                </p>

                <button
                  className="font-bold"
                  type="button"
                  onClick={addTeamMember}
                >
                  + Asignar equipo
                </button>
              </div>
            )}

            {state.showArtist || edit === true ? (
              <div className="relative mb-5  min-h-52 items-center justify-center rounded-lg border border-colorBorder p-10">
                <p className="absolute left-8 top-4 font-semibold text-gray-100">
                  Artista
                </p>
                <button
                  type="button"
                  className="absolute right-8 top-4 font-bold "
                  onClick={addArtistEvent}
                >
                  + Asignar artista
                </button>

                <div className="flex flex-wrap justify-center gap-4 pt-5">
                  {state.selectedArtists?.map((_, i) => (
                    <ArtistEvent
                      key={i}
                      data={_}
                      handleRemoveId={handleRemoveArtist}
                      onClick={() => handleSetMainArtist(_.id)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="relative mb-5 flex  min-h-52 items-center justify-center rounded-lg border border-colorBorder p-10">
                <p className="absolute left-8 top-4 font-semibold text-gray-100">
                  Artista
                </p>

                <button
                  className="font-bold"
                  type="button"
                  onClick={addArtistEvent}
                >
                  + Asignar artista
                </button>
              </div>
            )}

            {state.showValidator || edit === true ? (
              <div className="relative min-h-52  items-center justify-center rounded-lg border border-colorBorder p-10">
                <p className="absolute left-8 top-4 font-semibold text-gray-100">
                  Validador
                </p>
                <button
                  type="button"
                  className="absolute right-8 top-4 font-bold "
                  onClick={addValidatorEvent}
                >
                  + Asignar validador
                </button>

                <div className="flex flex-wrap justify-center gap-4 pt-5">
                  {state.selectedValidators?.map((_, i) => (
                    <ArtistEvent
                      key={i}
                      data={{ ..._.user[0], id: _.id }}
                      handleRemoveId={handleRemoveValidator}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="relative flex min-h-52  items-center justify-center rounded-lg border border-colorBorder p-10">
                <p className="absolute left-8 top-4 font-semibold text-gray-100">
                  Validador
                </p>

                <button
                  className="font-bold"
                  type="button"
                  onClick={addValidatorEvent}
                >
                  + Asignar validador
                </button>
              </div>
            )}

            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
              >
                Cancelar
              </button>
              {auth.role == "admin" && form.getValues("temp") && edit ? (
                <>
                  <button
                    type="submit"
                    onClick={() => {
                      statusEvent.current = "approved";
                    }}
                    className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                  >
                    Aprobar Cambios
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      statusEvent.current = "rejected";
                    }}
                    className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                  >
                    Rechazar Cambios
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  onClick={() => {}}
                  className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                >
                  {edit ? "Guardar" : "Publicar evento"}
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
