import { IOption } from "../types_new/IOption";

const statuses: IOption[] = [
  { value: "active", text: "Venta Activa" },
  { value: "activo", text: "Venta Activa" },
  { value: "pending", text: "Pendiente" },
  { value: "pendiente", text: "Pendiente aprobación" },
  { value: "paused", text: "Pausado" },
  { value: "send", text: "Enviado" },
  { value: "sent", text: "Enviado" },
  { value: "pending_approval", text: "Requiere aprobación" },
  { value: "finished", text: "Finalizado" },
];

export const getStatusFromValue = (status: string) => {
  let foundStatus = statuses.find((st) => st.value === status);
  if (!foundStatus) return status;
  return foundStatus.text;
};

export const getEventStatus = (status: string, temp?: string) => {
  if (temp && status != "finished") return "pending_approval";
  return status;
};
