const PERMISSIONS = {
  SUPER_ADMIN: "super_admin",
  TICKETERA: "ticketera",
  SOCIAL_MEDIA: "social_media",
  ADMIN: "admin",
  REPORTS: "reports",
  EVENTS: "events",
};

Object.freeze(PERMISSIONS);

export const whiteList = [
  "santiago.diaz@wearebombo.com",
  "ariel@wearebombo.com",
  // "bebe@admin.com",
  // "lean@admin.com",
  // "kevin.muter@wearebombo.com",
  // "pablopelt95@gmail.com",
  "reportes@avc.com",
  "reportesrio@wearebombo.com",
  "paunew@wearebombo.com",
  "market@wearebombo.com",
  "market@mute.com",
  "ariel@mute.com",
  "reporte@lajuanita.com",
  "admin@lajuanita.com",
  // "comercial@mute.com",
  "giorgiomarchesini@reportes.com",
  "giorgiomute@reportes.com",
  "admin@mute.com",
  "admin@vision.com",
  "admin@thecountymusic.com",
  "reportes@kook.com.ar",
];

export default PERMISSIONS;
