import { FC } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ScriptableContext,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

type selledTicketsProps = {
  title: string;
  tickets: number;
  stock: number;
  percentage?: number;
  dataChart?: Array<any>;
};

export const SelledTickets: FC<selledTicketsProps> = ({
  title = "Titulo",
  tickets = 123,
  stock = 0,
  percentage,
  dataChart = [],
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const labels = dataChart.map((d) => moment(d["_id"]).format("DD MMM"));
  const datasets = [
    {
      fill: true,
      label: "Tickets vendidos",
      data: dataChart.map((d) => d["qty"]),
      borderColor: "rgb(95, 220, 179)",
      backgroundColor: (context: ScriptableContext<"line">) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, "rgba(243, 254, 248,1)");
        gradient.addColorStop(1, "rgba(243, 254, 248,0)");
        return gradient;
      },
      lineTension: 0.4,
      borderWidth: 2,
    },
  ];
  const dataLocal = {
    labels,
    datasets,
  };

  const options = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  console.log(dataLocal);
  return (
    <div className="flex  w-60 flex-col justify-between">
      <h3 className="text-xl font-bold">{title}</h3>
      <p className="text-lg font-bold">
        {tickets} / {stock}
      </p>
      <p className="flex font-medium">Tickets vendidos</p>
    </div>
  );
};
